import { AppShell, Container } from "@mantine/core";
import { Routes, Route } from "react-router-dom";

import { HeaderSimple } from "../Components/Header";
import { FooterLinks } from "../Components/Footer";
import { NavbarSection } from "../Components/Navbar";
import { NotFoundPage } from "../Pages/NotFound";
import { AdminUsersPage } from "../Pages/Admin/User/List";
import { AdminPlansPage } from "../Pages/Admin/Plan/List";
import { AdminNetworkPage } from "../Pages/Admin/Network/List";
import { AdminProjectPage } from "../Pages/Admin/Project/List";


export const AdminLayout = ({header_links, footer_data, all_classes}: {header_links: any, footer_data: any, all_classes: any}) => {
  return (
    <AppShell
      padding="md"
      navbar={<NavbarSection />}
      header={<HeaderSimple links={header_links} />}
      footer={<FooterLinks data={footer_data} />}
    >
      <Container>
        <div className={all_classes.content}>
          <Routes>
            <Route path="/users" element={<AdminUsersPage />}></Route>
            <Route path="/plans" element={<AdminPlansPage />}></Route>
            <Route path="/networks" element={<AdminNetworkPage />}></Route>
            <Route path="/projects" element={<AdminProjectPage />}></Route>
            <Route path="/" element={<AdminUsersPage />}></Route>
            <Route path="*" element={<NotFoundPage />}></Route>
          </Routes>
        </div>
      </Container>
    </AppShell>
  )
}
