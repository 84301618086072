import { useEffect } from 'react';
import { Button } from '@mantine/core';
import { useGoogleLogin } from '@react-oauth/google';
import { IconBrandGoogle } from '@tabler/icons';
import { useSearchParams } from "react-router-dom";
import { useGoogleOneTapLogin } from '@react-oauth/google';
import { GOOGLE_LOGIN } from '../graphql/mutations';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { Error } from '../Components/Error';


export function LoginGoogleButton({loginCallback}: {loginCallback: any}) {
  const navigate = useNavigate();

  const [loginGoogle, loginGoogleData] = useMutation(GOOGLE_LOGIN, {
    onError() {
      navigate("/login");
    },
    onCompleted(data: any) {
      loginCallback(data.googleLogin);
    }
  });

  useGoogleOneTapLogin({
    onSuccess: credentialResponse => {
      loginGoogle({
        variables: {
          form: {
            token: credentialResponse["credential"]
          }
        }
      });
    },
    onError: () => {
      alert('Login Failed');
    },
  });

  const doLoginGoogle = useGoogleLogin({
    redirect_uri: process.env.REACT_APP_GOOGLE_REDIRECT_URL,
    ux_mode: "redirect",
    flow: "auth-code"
  });
  let [searchParams] = useSearchParams();

  useEffect(() => {
    let code = searchParams.get("code");
    if (code) {
      loginGoogle({
        variables: {
          form: {
            code: code
          }
        }
      });
    }
  }, [searchParams, loginGoogle])

  return (
    <>
      {!!loginGoogleData.error && <Error text={loginGoogleData.error.toString()} /> }
      <Button leftIcon={<IconBrandGoogle />} loading={loginGoogleData.loading} onClick={() => doLoginGoogle()} fullWidth>
        Sing in with Google
      </Button>
    </>
  )
}