import { useQuery } from '@apollo/client';

import { ActionIcon, Badge, Table, Card, CopyButton, Container, ScrollArea, Group, Skeleton, Pagination } from '@mantine/core';
import { GET_ADMIN_PROJECTS } from '../../../graphql/queries';
import { Error } from '../../../Components/Error';
import { useState } from 'react';
import { NetworkLogo } from '../../../Components/NetworkLogo';
import { IconCopy, IconCheck } from "@tabler/icons";


export function AdminProjectPage() {
  const perPage = 25;
  const [page, setPage] = useState(1);
  const { loading, error, data } = useQuery(GET_ADMIN_PROJECTS, {
    variables: {
      offset: (page - 1) * perPage,
      limit: perPage
    }
  });
  let rows = null;

  if (data) {
    rows = data.adminProjects.list.map((row: any) => {
      return (
        <tr key={row.id}>
          <td>{row.name}</td>
          <td>
            {row.user ? (
              <>
                {row.user.email}
                {row.user.fullName && (
                  <>
                    ({row.user.fullName})
                  </>
                )}
                <div>
                  {row.userId}
                </div>
              </>
            ) : (
              <>
                {row.userId}
              </>
            )}
          </td>
          <td>
            <div>
              <Badge color={"green"}>{row.plan.slug}</Badge> {row.plan.requestsPerMonth.toLocaleString()} reqs/month.
            </div>
            <div>
              <Badge color={row.status === "active" ? "green" : "red"}>{row.status}</Badge>
            </div>
          </td>
          <td>
            <NetworkLogo network={row.network.slug} size={20} /><Badge color={"blue"} title={row.network.title}>{row.network.slug}</Badge>
          </td>
          <td>
            {row.currentStats.total}
          </td>
          <td>
            <CopyButton value={row.token}>
              {({ copied, copy }) => (
                <ActionIcon color={copied ? 'teal' : 'gray'} onClick={copy}>
                {copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />}
                </ActionIcon>
              )}
            </CopyButton>
          </td>
        </tr>
      );
    });
  }
  return (
    <ScrollArea>
      { loading ? (
        <>
          <Skeleton height={8} mt={6} radius="xl" />
          <Skeleton height={8} mt={6} radius="xl" />
          <Skeleton height={8} mt={6} radius="xl" />
        </>
      ) : (
        <>
        { error ? (
          <Error text={error?.toString()} />
        ) : (
          <>
            <Group align="flex-end">
              <h2 style={{ flex: 1 }} >Projects, total {data.adminProjects.count}</h2>
            </Group>

            <Container>
              { rows && rows.length ? (
                <Table sx={{ minWidth: 800 }} verticalSpacing="xs">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>User</th>
                      <th>Plan/Status</th>
                      <th>Network</th>
                      <th>Used stats</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>{rows}</tbody>
                </Table>
              ) : (
                <Card>No projects. Please add one to get credentials.</Card>
              )}
              <Pagination value={page} total={Math.ceil(data.adminProjects.count / perPage)} onChange={setPage} />
            </Container>
          </>
        )}
        </>
      )}
    </ScrollArea>
  );
}