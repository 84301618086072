import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useForm } from '@mantine/form';

import { Modal, Box, Flex, SimpleGrid, Textarea, Select, FocusTrap, TextInput, Button, Text, Table, Card, Container, ScrollArea, Group, Badge, ActionIcon, Skeleton } from '@mantine/core';
import { IconTrash, IconPencil } from '@tabler/icons';
import { GET_ADMIN_PLANS } from '../../../graphql/queries';
import { ADD_ADMIN_PLAN, EDIT_ADMIN_PLAN, DELETE_ADMIN_PLAN } from '../../../graphql/mutations';
import { Error } from '../../../Components/Error';


export function AdminPlansPage() {
  const { loading, error, data } = useQuery(GET_ADMIN_PLANS);
  const [deletePlanId, setDeletePlanId] = useState(0);
  const [editPlanId, setEditPlanId] = useState(0);
  const [addNetworkOpened, setAddNetworkOpened] = useState(false);

  const [deletePlan] = useMutation(DELETE_ADMIN_PLAN, {
    refetchQueries: [{ query: GET_ADMIN_PLANS }],
    onCompleted() {
      setDeletePlanId(0);
    }
  });

  const [addPlan, addData] = useMutation(ADD_ADMIN_PLAN, {
    refetchQueries: [{ query: GET_ADMIN_PLANS }],
    onCompleted() {
      setAddNetworkOpened(false);
      form.reset();
    }
  });

  const [editPlan, editData] = useMutation(EDIT_ADMIN_PLAN, {
    refetchQueries: [{ query: GET_ADMIN_PLANS }],
    onCompleted() {
      setEditPlanId(0);
      form.reset();
    }
  });

  const form = useForm({
    initialValues: {
      slug: "",
      requestsPerMonth: 100000,
      rateLimit: 10,
      ratePeriod: 1,
      title: "New plan",
      description: "",
      visibility: "public",
      price: 10,
      currency: "USDT"
    }
  });

  const openDeleteDialog = (planId: number) => {
    setDeletePlanId(planId);
  };

  const openEditDialog = (planId:number, plan: any) => {
    setEditPlanId(planId);
    form.setValues(plan);
  }

  const closeDeleteDialog = () => {
    setDeletePlanId(0);
  };

  const doDeletePlan = () => {
    deletePlan({
      variables: {
        planId: deletePlanId
      }
    });
  };

  const doCreatePlan = () => {
    addPlan({
      variables: {
        form: prepareForm()
      }
    });
  };

  const openAddDialog = () => {
    form.reset();
    setAddNetworkOpened(true);
  };

  const closeAddPlanDialog = () => {
    setAddNetworkOpened(false);
  };

  const closeEditPlanDialog = () => {
    setEditPlanId(0);
  };

  const prepareForm = () => {
    let values: any = form.values;

    delete values["updatedAt"];
    delete values["createdAt"];
    delete values["__typename"];
    delete values["id"];
    if (!values["price"]) {
      values["price"] = null;
    }
    values["requestsPerMonth"] = parseInt(values["requestsPerMonth"]);
    values["rateLimit"] = parseInt(values["rateLimit"]);
    values["ratePeriod"] = parseInt(values["ratePeriod"]);

    return values;
  }

  const doSavePlan = () => {
    editPlan({
      variables: {
        planId: editPlanId,
        form: prepareForm()
      }
    });
  }

  let rows = null;

  if (data) {
    rows = data.adminPlans.map((row: any) => {
      return (
        <tr key={row.id}>
          <td>{row.slug}</td>
          <td>{row.title}</td>
          <td>{row.description}</td>
          <td>{row.requestsPerMonth}<br />{row.rateLimit} per {row.ratePeriod}</td>
          <td>
            {row.price && <Badge>{parseInt(row.price)} {row.currency}</Badge>}
          </td>
          <td>
            {row.visibility === "private" && <Badge color="red">{row.visibility}</Badge>}
            {row.visibility !== "private" && <Badge color="green">{row.visibility}</Badge>}
          </td>
          <td>
            <Group spacing={0} position="right">
              <Flex direction="row">
                <ActionIcon onClick={() => openEditDialog(parseInt(row.id), row)} color="green">
                  <IconPencil size={16} stroke={1.5} />
                </ActionIcon>
                <ActionIcon onClick={() => openDeleteDialog(parseInt(row.id))} color="red">
                  <IconTrash size={16} stroke={1.5} />
                </ActionIcon>
              </Flex>
            </Group>
          </td>
        </tr>
      );
    });
  }
  return (
    <ScrollArea>
      <Group align="flex-end">
        <h2 style={{ flex: 1 }} >Plans</h2>
        <Button my={20} onClick={openAddDialog} color={"green"}>Add</Button>
      </Group>

      <Modal opened={addNetworkOpened} title="Add new plan" withCloseButton onClose={closeAddPlanDialog} size="lg" radius="md">
        <FocusTrap active={addNetworkOpened}>
          <Error text={addData?.error?.toString()} />
          <Box>
            <TextInput label="Slug" placeholder="Name" {...form.getInputProps('slug')} />
            <TextInput label="Title" placeholder="Title" {...form.getInputProps('title')} />
            <Textarea label="Description" placeholder="Desciption" {...form.getInputProps('description')} />
            <SimpleGrid cols={3}>
              <TextInput label="Requests per month" placeholder="reqs" {...form.getInputProps('requestsPerMonth')} />
              <TextInput label="Rate limit" placeholder="" {...form.getInputProps('rateLimit')} />
              <TextInput label="Rate period" placeholder="" {...form.getInputProps('ratePeriod')} />
            </SimpleGrid>
            <SimpleGrid cols={2}>
              <TextInput label="Price" placeholder="" {...form.getInputProps('price')} />
              <Select label="Currency" placeholder="" data={['USDT']} {...form.getInputProps('currency')} />
            </SimpleGrid>
            <Select label="Visibility" placeholder="" data={['public', 'private']} {...form.getInputProps('visibility')} />
          </Box>
          <Button color={"green"} loading={addData.loading} onClick={doCreatePlan}>Add</Button>
        </FocusTrap>
      </Modal>

      <Modal opened={!!editPlanId} title="Edit plan {editPlan?.slug}" withCloseButton onClose={closeEditPlanDialog} size="lg" radius="md">
        <FocusTrap active={!!editPlanId}>
          <Error text={editData?.error?.toString()} />
          <Box>
            <TextInput label="Slug" placeholder="Name" {...form.getInputProps('slug')} />
            <TextInput label="Title" placeholder="Title" {...form.getInputProps('title')} />
            <Textarea label="Description" placeholder="Desciption" {...form.getInputProps('description')} />
            <SimpleGrid cols={3}>
              <TextInput label="Requests per month" placeholder="reqs" {...form.getInputProps('requestsPerMonth')} />
              <TextInput label="Rate limit" placeholder="" {...form.getInputProps('rateLimit')} />
              <TextInput label="Rate period" placeholder="" {...form.getInputProps('ratePeriod')} />
            </SimpleGrid>
            <SimpleGrid cols={2}>
              <TextInput label="Price" placeholder="" {...form.getInputProps('price')} />
              <Select label="Currency" placeholder="" data={['USDT']} {...form.getInputProps('currency')} />
            </SimpleGrid>
            <Select label="Visibility" placeholder="" data={['public', 'private']} {...form.getInputProps('visibility')} />
          </Box>
          <Button color={"green"} loading={editData.loading} onClick={doSavePlan}>Save</Button>
        </FocusTrap>
      </Modal>

      <Modal opened={!!deletePlanId} title="Delete plan" withCloseButton onClose={closeDeleteDialog} size="lg" radius="md">
        <Text size="sm" mb="xs" weight={500}>
          Are you sure to delete plan?
        </Text>

        <Group align="flex-end">
          <Button color={"red"} onClick={doDeletePlan}>Delete</Button>
        </Group>
      </Modal>

      { loading ? (
        <>
          <Skeleton height={8} mt={6} radius="xl" />
          <Skeleton height={8} mt={6} radius="xl" />
          <Skeleton height={8} mt={6} radius="xl" />
        </>
      ) : (
        <>
        { error ? (
          <Error text={error?.toString()} />
        ) : (
          <Container>
            { rows && rows.length ? (
              <Table sx={{ minWidth: 800 }} verticalSpacing="xs">
                <thead>
                  <tr>
                    <th>Slug</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Plan</th>
                    <th>Price</th>
                    <th>Visibility</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </Table>
            ) : (
              <Card>No plans.</Card>
            )}
          </Container>
        )}
        </>
      )}
    </ScrollArea>
  );
}