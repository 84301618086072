import { gql } from '@apollo/client';
import { CORE_USER_FIELDS } from './fragments';


export const EDIT_PROJECT = gql`
  mutation editProject($projectId: Int!, $name: String!) {
    editProject(projectId: $projectId, name: $name) {
      id
      token
      name
    }
  }
`;


export const DELETE_PROJECT = gql`
  mutation deleteProject($projectId: Int!) {
    deleteProject(projectId: $projectId) {
      id
      token
      name
    }
  }
`;


export const ADD_PROJECT = gql`
  mutation addProject($name: String!, $networkSlug: String!) {
    addProject(name: $name, networkSlug: $networkSlug) {
      id
      token
      name
    }
  }
`;


export const VERIFY_EMAIL = gql`
  mutation verifyEmail($form: VerifyEmailFormInput!) {
    verifyEmail(form: $form)
  }
`;


export const LOGIN = gql`
  ${CORE_USER_FIELDS}
  mutation login($form: LoginFormInput!) {
    login(form: $form) {
      refreshToken
      token
      tokenExpirationInstant
      user {
        ...CoreUserFields
      }
    }
  }
`;

export const GOOGLE_LOGIN = gql`
  ${CORE_USER_FIELDS}
  mutation GoogleLogin($form: GoogleLoginInput!) {
    googleLogin(form: $form) {
      refreshToken
      token
      tokenExpirationInstant
      user {
        ...CoreUserFields
      }
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation refreshToken($form: RefreshTokenFormInput!) {
    refreshToken(form: $form) {
      refreshToken
      token
    }
  }
`;


export const SIGNUP = gql`
  ${CORE_USER_FIELDS}
  mutation signup($form: SignupFormInput!) {
    signup(form: $form) {
      token
      tokenExpirationInstant
      user {
        ...CoreUserFields
      }
      refreshToken
    }
  }
`;


export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($form: ForgotPasswordFormInput!) {
    forgotPassword(form: $form)
  }
`;


export const CHANGE_PASSWORD = gql`
  mutation changePassword($form: ChangePasswordFormInput!) {
    changePassword(form: $form)
  }
`;


export const VALIDATE_PAYMENT = gql`
  mutation ValidatePayment($txHash: String!) {
    validatePayment(txHash: $txHash)
  }
`;


export const ADD_ADMIN_PLAN = gql`
  mutation AdminAddPlan($form: PlanForm!) {
    adminAddPlan(form: $form) {
      id
      slug
      createdAt
      updatedAt
      requestsPerMonth
      rateLimit
      ratePeriod
      title
      description
      visibility
      price
      currency
    }
  }
`;

export const EDIT_ADMIN_PLAN = gql`
  mutation AdminEditPlan($planId: Int!, $form: PlanForm!) {
    adminEditPlan(planId: $planId, form: $form) {
      id
      slug
      createdAt
      updatedAt
      requestsPerMonth
      rateLimit
      ratePeriod
      title
      description
      visibility
      price
      currency
    }
  }
`;


export const DELETE_ADMIN_PLAN = gql`
  mutation deletePlan($planId: Int!) {
    adminDeletePlan(planId: $planId)
  }
`;


export const EDIT_ADMIN_USER_PLAN = gql`
  mutation AdminUpdateUserPlan(
    $userId: String!,
    $planSlug: String!,
    $planEndDate: DateTime,
    $billingBeginDate: DateTime,
    $blockedTill: DateTime
  ) {
    adminUpdateUserPlan(
      userId: $userId,
      planSlug: $planSlug,
      planEndDate: $planEndDate,
      billingBeginDate: $billingBeginDate,
      blockedTill: $blockedTill
    )
  }`;


export const ADD_ADMIN_NETWORK = gql`
  mutation AdminAddNetwork($form: NetworkForm!) {
    adminAddNetwork(form: $form) {
      slug
      title
      domain
      headerInDomain
      headerInPath
      createdAt
      updatedAt
      status
    }
  }
`;


export const EDIT_ADMIN_NETWORK = gql`
  mutation AdminEditNetwork($networkSlug: String!, $form: NetworkForm!) {
    adminEditNetwork(networkSlug: $networkSlug, form: $form) {
      slug
      title
      domain
      headerInDomain
      headerInPath
      createdAt
      updatedAt
      status
    }
  }
`;


export const DELETE_ADMIN_NETWORK = gql`
  mutation AdminDeleteNetwork($networkSlug: String!) {
    adminDeleteNetwork(networkSlug: $networkSlug)
  }
`;