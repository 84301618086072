import { useState } from 'react';
import { createStyles, Navbar, Anchor, getStylesRef, rem, Paper, Text, Badge, Flex } from '@mantine/core';
import { IconDatabaseImport, IconAntenna, IconLogout, IconBusinessplan, IconUsers, IconFolders, IconTags } from '@tabler/icons';
import { useNavigate } from 'react-router-dom';
import { UserButton } from './UserButton';
import { useStore } from '../store';
import { Link } from 'react-router-dom';
import { DateTime } from "luxon";

const DATE_FORMAT = "yyyy/LL/dd"


const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },

  title: {
    textTransform: 'uppercase',
    letterSpacing: rem(-0.25),
  },

  link: {
    ...theme.fn.focusStyles(),
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    fontSize: theme.fontSizes.sm,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7],
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,

      [`& .${getStylesRef('icon')}`]: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      },
    },
  },

  linkIcon: {
    ref: getStylesRef('icon'),
    color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[6],
    marginRight: theme.spacing.sm,
  },

  linkActive: {
    '&, &:hover': {
      backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
      color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
      [`& .${getStylesRef('icon')}`]: {
        color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
      },
    },
  },

  footer: {
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
    paddingTop: theme.spacing.md,
  },
}));

const linksData = [
  { link: '/dashboard', label: 'Projects', icon: IconDatabaseImport },
  { link: '/dashboard/pricing', label: 'Pricing', icon: IconBusinessplan },
];

const adminLinksData = [
  { link: '/admin/users', label: 'Users', icon: IconUsers },
  { link: '/admin/plans', label: 'Plans', icon: IconTags },
  { link: '/admin/networks', label: 'Networks', icon: IconAntenna },
  { link: '/admin/projects', label: 'All Projects', icon: IconFolders },
];

export function NavbarSection() {
  const { classes, cx } = useStyles();
  const [active, setActive] = useState('Projects');
  const navigate = useNavigate();
  let { user } = useStore();

  if (!user.isLoading && !user.isLoggedIn) {
    navigate("/");
  }

  const signOut = () => {
    user.logOut();
    navigate("/");
  }

  const menuItem = (item: any) => {
    return (
      <Anchor
        component={Link}
        to={item.link}
        className={cx(classes.link, { [classes.linkActive]: item.label === active })}
        key={item.label}
        onClick={() => {
          setActive(item.label);
        }}
      >
        <item.icon className={classes.linkIcon} stroke={1.5} />
        <span>{item.label}</span>
      </Anchor>
    );
  };

  const links = linksData.map((item) => menuItem(item));
  const adminLinks = adminLinksData.map((item) => menuItem(item));

  return (
    <Navbar width={{ sm: 300 }} p="md">
      <Navbar.Section>
        <UserButton
          image={user.props?.getImageUrl(80) || ""}
          name={user.props?.username || ""}
          email={user.props?.email || ""}
        />
      </Navbar.Section>
      <Navbar.Section>
        <Paper radius={5} withBorder p={"md"} my={"md"}>
          <Flex direction="column" gap="xs" justify="center" align="center">
            <Flex direction="row" gap="md" style={{width: "100%", justifyContent: "space-between"}} align="center">
              <Text>Plan:</Text>
              <Badge color="green">{user.props?.plan?.slug}</Badge>
            </Flex>
            { user.props?.data?.planEndDate && (
              <Flex direction="row" gap="md" style={{width: "100%", justifyContent: "space-between"}} align="center">
                <Text>Plan end date:</Text>
                <Badge color="green">{DateTime.fromISO(user.props?.data?.planEndDate).toFormat(DATE_FORMAT)}</Badge>
              </Flex>
            )}
            { user.props?.data?.blockedTill && (
              <Flex direction="row" gap="md" style={{width: "100%", justifyContent: "space-between"}} align="center">
                <Text>Blocked till:</Text>
                <Badge color="red">{DateTime.fromISO(user.props?.data?.blockedTill).toFormat(DATE_FORMAT)}</Badge>
              </Flex>
            )}
            <Flex direction="row" gap="md" style={{width: "100%", justifyContent: "space-between"}} align="center">
              <Flex direction={"column"}>
                <Text color="dimmed" size="xs">current</Text>
                <Text>{user.props?.stats?.total.toLocaleString()}</Text>
              </Flex>
              <Flex direction={"column"}>
                <Text color="dimmed" size="xs">max</Text>
                <Text>/ {user.props?.plan.requestsPerMonth.toLocaleString()}</Text>
              </Flex>
            </Flex>
          </Flex>
        </Paper>
      </Navbar.Section>

      <Navbar.Section grow>
        {links}
      </Navbar.Section>

      {user.props?.isAdmin && (
        <>
          <h2>Admin</h2>
          <Navbar.Section grow>
            {adminLinks}
          </Navbar.Section>
        </>
      )}

      <Navbar.Section className={classes.footer}>
        <Anchor className={classes.link} onClick={signOut}>
          <IconLogout className={classes.linkIcon} stroke={1.5} />
          <span>Logout</span>
        </Anchor>
      </Navbar.Section>
    </Navbar>
  );
}