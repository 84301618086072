import { useQuery } from '@apollo/client';
import { Grid, Title, Center } from '@mantine/core';

import { PlanBlock } from '../Components/PlanBlock';
import { HeroBullets } from '../Components/HeroBlock';
import { GET_DASHBOARD } from '../graphql/queries';

export function MainPage() {
  const { loading, error, data } = useQuery(GET_DASHBOARD);
  console.log(loading, error, data);

  return (
    <>
      <HeroBullets />
      <Center m={10}><Title>Pricing</Title></Center>
      {data && (
        <Grid>
          {data.plans.map((plan: any) => (
            <Grid.Col key={plan.slug} span={4}><PlanBlock plan={plan} showBuyButton={false} /></Grid.Col>
          ))}
        </Grid>
      )}
    </>
  )
}
