import { gql } from '@apollo/client';

export const CORE_USER_FIELDS = gql`
  fragment CoreUserFields on UserResponse {
    id
    active
    verified
    data {
      planSlug
      planEndDate
      blockedTill
    }
    email
    firstName
    lastName
    isAdmin
    currentStats {
      total
      begin
      end
    }
    plan {
      id
      slug
      createdAt
      updatedAt
      requestsPerMonth
      rateLimit
      ratePeriod
    }
  }
`;
