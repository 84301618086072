import { Group, Anchor } from '@mantine/core';
import logo from '../assets/images/tem.svg';
import { useStyles } from '../styles';


export function TemLogo({ size } : {size: number}) {
  const { classes } = useStyles();

  return (
    <Group>
      <Anchor href="https://tronenergy.market/" target="_blank" size="lg" className={classes.logo}>
        <img src={logo} alt="logo" width={size} />
      </Anchor>
    </Group>
  );
}