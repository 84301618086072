import { useState } from "react";
import { Center, Title, Paper, Breadcrumbs, Badge, Skeleton, Anchor, Flex, Button, TextInput, Box } from "@mantine/core";
import { useQuery, useMutation } from "@apollo/client";
import { Error } from "../../Components/Error";
import { Link } from "react-router-dom";
import { ADD_PROJECT } from '../../graphql/mutations';
import { GET_PROJECTS, GET_NETWORKS } from '../../graphql/queries';
import { NetworkLogo } from '../../Components/NetworkLogo';
import { useNavigate } from "react-router-dom";


export function ProjectAddPage () {
  const { loading, error, data } = useQuery(GET_NETWORKS);

  const [projectName, setProjectName] = useState("");
  const [network, setNetwork] = useState("tron-mainnet");
  const navigate = useNavigate();

  const items = [
    <Anchor component={Link} to="/dashboard/" key={0}>
      Projects list
    </Anchor>,
    <Anchor component={Link} to={`/dashboard/project/add`} key={1}>
      Add project
    </Anchor>,
  ];

  const [addProject, addData] = useMutation(ADD_PROJECT, {
    refetchQueries: [{ query: GET_PROJECTS }],
    onCompleted(data) {
      setProjectName("");
      navigate(`/dashboard/project/${data.addProject.id}`);
    }
  });

  const doCreateProject = () => {
    addProject({
      variables: {
        name: projectName,
        networkSlug: network
      }
    });
  };

  const maybeSubmit = (e: any) => {
    if (e.keyCode === 13) {
      doCreateProject();
    }
  };

  const doSetProjectName = (event: any) => {
    setProjectName(event.target.value);
  };

  const setNetworkPaper = (event: any) => {
    setNetwork(event.target.dataset.network);
  }

  const isActive = (network: string) : boolean => {
    for (let row of data.networks) {
      if (row.slug === network) {
        return row.status === "active";
      }
    }
    return false;
  }

  return (
    <>
      <Paper mb={20}>
        <Breadcrumbs separator="→" mt="xs">{items}</Breadcrumbs>
      </Paper>

      { loading ? (
        <>
          <Skeleton height={8} mt={6} radius="xl" />
          <Skeleton height={8} mt={6} radius="xl" />
          <Skeleton height={8} mt={6} radius="xl" />
        </>
      ) : (
        <>
        { error ? (
          <Error text={error?.toString()} />
        ) : (

          <Flex direction="column" gap="xl">
            <Error text={addData?.error?.toString()} />

            <TextInput
              minLength={3}
              label="Project name"
              data-autofocus
              autoFocus
              onKeyDown={maybeSubmit}
              required sx={{ flex: 1 }}
              onChange={doSetProjectName}
              value={projectName}
              />

            <Box my="xl" mx="xl">
              <Flex gap="xl" justify="center" align="center" direction="column">
                <Title>Mainnets</Title>
                <Flex gap="xl" justify="center" align="center" direction="row">
                  {isActive("tron-mainnet") && (
                    <Paper style={{
                      borderWidth: network === "tron-mainnet" ? "2px" : "0",
                      borderStyle: "solid",
                      borderColor: "blue",
                      cursor: "pointer"
                      }} p="xs" >
                      <Flex
                        gap="md"
                        justify="center"
                        align="center"
                        direction="column"
                        data-network="tron-mainnet"
                        onClick={setNetworkPaper}>
                        <NetworkLogo network="tron-mainnet" size={128} />
                        <Badge>Tron mainnet</Badge>
                      </Flex>
                    </Paper>
                  )}
                  {isActive("ethereum-mainnet") && (
                    <Paper style={{
                      borderWidth: network === "ethereum-mainnet" ? "2px" : "0",
                      borderStyle: "solid",
                      borderColor: "blue",
                      cursor: "pointer"
                      }} p="xs">
                      <Flex
                        gap="md"
                        justify="center"
                        align="center"
                        direction="column"
                        data-network="ethereum-mainnet"
                        onClick={setNetworkPaper}>
                        <NetworkLogo network="ethereum-mainnet" size={128} />
                        <Badge>Ethereum mainnet</Badge>
                      </Flex>
                    </Paper>
                  )}
                  {isActive("arbitrum-one") && (
                    <Paper style={{
                      borderWidth: network === "arbitrum-one" ? "2px" : "0",
                      borderStyle: "solid",
                      borderColor: "blue",
                      cursor: "pointer"
                      }} p="xs">
                      <Flex
                        gap="md"
                        justify="center"
                        align="center"
                        direction="column"
                        data-network="arbitrum-one"
                        onClick={setNetworkPaper}>
                        <NetworkLogo network="arbitrum-one" size={128} />
                        <Badge>Arbitrum one</Badge>
                      </Flex>
                    </Paper>
                  )}
                </Flex>
                <br />
                <Title>Testnets</Title>
                <Flex gap="xl" justify="center" align="center" direction="row">
                  {isActive("tron-nile") && (
                    <Paper style={{
                      borderWidth: network === "tron-nile" ? "2px" : "0",
                      borderStyle: "solid",
                      borderColor: "blue",
                      cursor: "pointer"
                      }} p="xs">
                      <Flex
                        gap="md"
                        justify="center"
                        align="center"
                        direction="column"
                        data-network="tron-nile"
                        onClick={setNetworkPaper}>
                        <NetworkLogo network="tron-nile" size={128} />
                        <Badge>Tron nile</Badge>
                        <Badge color="red">dev</Badge>
                      </Flex>
                    </Paper>
                  )}
                </Flex>
              </Flex>
            </Box>

            <Center>
              <Button size="md" color={"green"} loading={addData.loading} onClick={doCreateProject}>Add</Button>
            </Center>
          </Flex>
        )}
        </>
      )}
    </>
  );
}