import { Prism } from '@mantine/prism';
import { Text, Card } from "@mantine/core";
import { useStyles } from "../../styles";

export function ProjectDoc({ project }: {project: any}) {
  let code = "";
  let code2 = "";
  const { classes } = useStyles();

  if (!project.network.slug.startsWith("tron")) {
    return <></>;
  }

  if (project.network.slug.startsWith("tron")) {
    code = `curl --location --request POST 'https://${project.network.domain}/wallet/getnowblock' --header 'Authorization: ${project.token}'`;
  }
  code2 = `curl --location --request POST 'https://${project.token}.${project.network.domain}/wallet/getnowblock'`;

  return (
    <Card mb={20}>
      <Text fz="xl" mb={30} className={classes.label}>
        Example of request
      </Text>
      <Prism mb={30} language="bash">{code}</Prism>
      {project.network.headerInDomain && (
        <>
          <Text mb={30} fz="xl" className={classes.label}>
            Or another variant. Using token in domain
          </Text>
          <Prism language="bash">{code2}</Prism>
        </>
      )}
    </Card>
  )
}