import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useForm } from '@mantine/form';

import { Modal, Box, Flex, Checkbox, SimpleGrid, Select, FocusTrap, TextInput, Button, Text, Table, Card, Container, ScrollArea, Group, Badge, ActionIcon, Skeleton } from '@mantine/core';
import { IconTrash, IconPencil, IconCircleCheck } from '@tabler/icons';
import { GET_ADMIN_NETWORKS } from '../../../graphql/queries';
import { ADD_ADMIN_NETWORK, EDIT_ADMIN_NETWORK, DELETE_ADMIN_NETWORK } from '../../../graphql/mutations';
import { Error } from '../../../Components/Error';
import { NetworkLogo } from '../../../Components/NetworkLogo';


export function AdminNetworkPage() {
  const { loading, error, data } = useQuery(GET_ADMIN_NETWORKS);
  const [deleteNetworkId, setDeleteNetworkId] = useState("");
  const [editnetworkId, setEditNetworkId] = useState("");
  const [addNetworkOpened, setAddNetworkOpened] = useState(false);

  const [deleteNetwork] = useMutation(DELETE_ADMIN_NETWORK, {
    refetchQueries: [{ query: GET_ADMIN_NETWORKS }],
    onCompleted() {
      setDeleteNetworkId("");
    }
  });

  const [addNetwork, addData] = useMutation(ADD_ADMIN_NETWORK, {
    refetchQueries: [{ query: GET_ADMIN_NETWORKS }],
    onCompleted() {
      setAddNetworkOpened(false);
      form.reset();
    }
  });

  const [editNetwork, editData] = useMutation(EDIT_ADMIN_NETWORK, {
    refetchQueries: [{ query: GET_ADMIN_NETWORKS }],
    onCompleted() {
      setEditNetworkId("");
      form.reset();
    }
  });

  const form = useForm({
    initialValues: {
      slug: "",
      title: "",
      domain: "",
      headerInDomain: false,
      headerInPath: false,
      status: "active"
    }
  });

  const openDeleteDialog = (networkId: string) => {
    setDeleteNetworkId(networkId);
  };

  const openEditDialog = (networkId: string, network: any) => {
    setEditNetworkId(networkId);
    console.log(network);
    form.setValues(network);
  }

  const closeDeleteDialog = () => {
    setDeleteNetworkId("");
  };

  const doDeleteNetwork = () => {
    deleteNetwork({
      variables: {
        networkSlug: deleteNetworkId
      }
    });
  };

  const doCreateNetwork = () => {
    addNetwork({
      variables: {
        form: prepareForm()
      }
    });
  };

  const openAddDialog = () => {
    form.reset();
    setAddNetworkOpened(true);
  };

  const closeAddNetworkDialog = () => {
    setAddNetworkOpened(false);
  };

  const closeEditNetworkDialog = () => {
    setEditNetworkId("");
  };

  const prepareForm = () => {
    let values: any = form.values;

    delete values["updatedAt"];
    delete values["createdAt"];
    delete values["__typename"];
    delete values["id"];

    return values;
  }

  const doSaveNetwork = () => {
    editNetwork({
      variables: {
        networkSlug: editnetworkId,
        form: prepareForm()
      }
    });
  }

  let rows = null;

  if (data) {
    rows = data.adminNetworks.map((row: any) => {
      return (
        <tr key={row.id}>
          <td>
            <Flex gap={"xs"}>
              <NetworkLogo network={row.slug} size={20} />
              {row.slug}
            </Flex>
          </td>
          <td>{row.title}</td>
          <td>{row.domain}</td>
          <td>
            {row.headerInDomain ? <IconCircleCheck /> : "-"} /
            {row.headerInPath ? <IconCircleCheck /> : "-"}
          </td>
          <td>
            {row.status === "active" && <Badge color="green">{row.status}</Badge>}
            {row.status !== "active" && <Badge color="red">{row.status}</Badge>}
          </td>
          <td>
            <Group spacing={0} position="right">
              <Flex direction="row">
                <ActionIcon onClick={() => openEditDialog(row.slug, row)} color="green">
                  <IconPencil size={16} stroke={1.5} />
                </ActionIcon>
                <ActionIcon onClick={() => openDeleteDialog(row.slug)} color="red">
                  <IconTrash size={16} stroke={1.5} />
                </ActionIcon>
              </Flex>
            </Group>
          </td>
        </tr>
      );
    });
  }
  return (
    <ScrollArea>
      <Group align="flex-end">
        <h2 style={{ flex: 1 }} >Networks</h2>
        <Button my={20} onClick={openAddDialog} color={"green"}>Add</Button>
      </Group>

      <Modal opened={addNetworkOpened} title="Add new network" withCloseButton onClose={closeAddNetworkDialog} size="lg" radius="md">
        <FocusTrap active={addNetworkOpened}>
          <Error text={addData?.error?.toString()} />
          <Box>
            <TextInput label="Slug" placeholder="Name" {...form.getInputProps('slug')} />
            <TextInput label="Title" placeholder="Title" {...form.getInputProps('title')} />
            <TextInput label="Domain" placeholder="Domain" {...form.getInputProps('domain')} />
            <SimpleGrid cols={2} my={20}>
              <Checkbox label="header in domain" checked={form.values.headerInDomain} {...form.getInputProps('headerInDomain')} />
              <Checkbox label="header in path" checked={form.values.headerInPath} {...form.getInputProps('headerInPath')} />
            </SimpleGrid>
            <Select label="Status" placeholder="" data={['active', 'inactive']} {...form.getInputProps('status')} />
          </Box>
          <Button color={"green"} loading={addData.loading} onClick={doCreateNetwork}>Add</Button>
        </FocusTrap>
      </Modal>

      <Modal opened={!!editnetworkId} title="Edit network {editNetwork?.slug}" withCloseButton onClose={closeEditNetworkDialog} size="lg" radius="md">
        <FocusTrap active={!!editnetworkId}>
          <Error text={editData?.error?.toString()} />
          <Box>
            <TextInput label="Slug" placeholder="Name" {...form.getInputProps('slug')} />
            <TextInput label="Title" placeholder="Title" {...form.getInputProps('title')} />
            <TextInput label="Domain" placeholder="Domain" {...form.getInputProps('domain')} />
            <SimpleGrid cols={2} my={20}>
              <Checkbox label="header in domain" checked={form.values.headerInDomain} {...form.getInputProps('headerInDomain')} />
              <Checkbox label="header in path" checked={form.values.headerInPath} {...form.getInputProps('headerInPath')} />
            </SimpleGrid>
            <Select label="Status" placeholder="" data={['active', 'inactive']} {...form.getInputProps('status')} />
          </Box>
          <Button color={"green"} loading={editData.loading} onClick={doSaveNetwork}>Save</Button>
        </FocusTrap>
      </Modal>

      <Modal opened={!!deleteNetworkId} title="Delete network" withCloseButton onClose={closeDeleteDialog} size="lg" radius="md">
        <Text size="sm" mb="xs" weight={500}>
          Are you sure to delete network?
        </Text>

        <Group align="flex-end">
          <Button color={"red"} onClick={doDeleteNetwork}>Delete</Button>
        </Group>
      </Modal>

      { loading ? (
        <>
          <Skeleton height={8} mt={6} radius="xl" />
          <Skeleton height={8} mt={6} radius="xl" />
          <Skeleton height={8} mt={6} radius="xl" />
        </>
      ) : (
        <>
        { error ? (
          <Error text={error?.toString()} />
        ) : (
          <Container>
            { rows && rows.length ? (
              <Table sx={{ minWidth: 800 }} verticalSpacing="xs">
                <thead>
                  <tr>
                    <th>Slug</th>
                    <th>Title</th>
                    <th>Domain</th>
                    <th>header domain/path</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </Table>
            ) : (
              <Card>No networks.</Card>
            )}
          </Container>
        )}
        </>
      )}
    </ScrollArea>
  );
}